const ready = (callback) => {

	if (document.readyState != 'loading') callback();
	else document.addEventListener('DOMContentLoaded', callback);

}


// Doc ready

ready(() => {

	// SMOOTH SCROLL TO ANCHORS

	const anchors = document.querySelectorAll('a[href^="#"]:not([href="#"])');

	anchors.forEach(trigger => {

		trigger.onclick = function(e) {

			e.preventDefault();
			let hash = this.getAttribute('href');
			let target = document.querySelector(hash);
			let headerOffset = 0;
			let elementPosition = target.offsetTop;
			let offsetPosition = elementPosition - headerOffset;

			window.scrollTo({

				top: offsetPosition,
				behavior: 'smooth'

			});
		};
	});



	// BACK TO TOP

	//Get the button
	const topButton = document.getElementById('top');

	// When the user scrolls down 100px from the top of the document, show the button
	window.onscroll = function () {

		scrollFunction();

	};

	function scrollFunction() {
		if (
			document.body.scrollTop > 0 ||
			document.documentElement.scrollTop > 0
			) {

			topButton.style.display = 'block';

	} else {

		topButton.style.display = 'none';

	}
}

	// When the user clicks on the button, scroll to the top of the document
	topButton.addEventListener('click', backToTop);

	function backToTop() {

		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;

	}




	// CONTACTBOX FORM

	if (document.getElementById('nomesia_form')) {
		
		// SHOW FORM SELECT "TIPOLOGIA_LEAD" ONLY IF OPTIONS > 1

		const tipologiaLead = document.querySelector('#tipologia_lead');
		
		const tipologiaLeadOptions = tipologiaLead.options.length;
		
		if( tipologiaLeadOptions > 1 ) {

			tipologiaLead.parentElement.classList.remove('d-none');
			tipologiaLead.classList.add('required');

			const firstOption = new Option('Seleziona...', '');
			tipologiaLead.insertBefore(firstOption, tipologiaLead.firstChild);
			tipologiaLead.selectedIndex = 0;

			const validationDiv = document.createElement('div');
			validationDiv.classList.add('field_status');
			tipologiaLead.after(validationDiv);

		} else {

			tipologiaLead.parentElement.classList.add('d-none');

		}
		

		// FORM REPLACE

		// Submit button text "Invia la richiesta" (instead of "Invia")
		const inputSumbit = document.querySelector('#nomesia_form input[type="submit"]');
		inputSumbit.value = 'Invia la richiesta';

		// Add span class ast (asterisk red) on required input labels
		const inputRequired = document.querySelectorAll('.required');
		inputRequired.forEach(function(input) {

			const spanAst = document.createElement('span');
			spanAst.classList.add('ast');
			spanAst.textContent = '*';
			input.parentElement.querySelector('label').appendChild(spanAst);

		});



		// Onclick privacy cookie same as Contactbox

		// const linkPolicy = document.querySelector('#nomesia_form #more_info_container label a');
		// const hrefPolicy = linkPolicy.getAttribute('onclick');

		// const policyLink = document.querySelector('#policylink');

		// if (policyLink) {
		// 	policyLink.setAttribute('onclick', hrefPolicy);
		// 	policyLink.addEventListener('click', function(e){
		// 		e.preventDefault();
		// 	});
		// }

	// } else {

		// const hrefPolicy = "window.open('https://cruscotto.contactbox.it/leadsubmit/privacy_ita_dynamic.htm?nome_cliente=Nomesia s.r.l.&iva=06250350961&indirizzo=via giovanni bovio 6&provincia=MI&tel=0287382386&fax=0293661693', 'titolo', ' width=380, height=600, hidthresizable=yes, status=0, scrollbars=yes, location');";

		// const policyLink = document.querySelector('#policylink');

		// if (policyLink) {
		// 	policyLink.setAttribute('onclick', hrefPolicy);
		// 	policyLink.addEventListener('click', function(e){
		// 		e.preventDefault();
		// 	});
		// }

	}



	// UTM Tracking

	var test = false;

	if (test == true){

		// ?utm_source=value_source&utm_medium=value_medium&utm_campaign=value_campaign&utm_term=value_term&utm_content=value_content
		if (document.getElementById('nomesia_form') !== null){
			document.getElementsByName('source')[0].removeAttribute('type');
			document.getElementsByName('medium')[0].removeAttribute('type');
			document.getElementsByName('campaign')[0].removeAttribute('type');
			document.getElementsByName('term')[0].removeAttribute('type');				
		}

	}

	var queryStringParams = {
		'labels' : ['source', 'medium',	'campaign', 'term', 'content'],
		'values' : []			
	};

	function getCookie(cname) {
		var name = cname + '=';
		var ca = document.cookie.split(';');
		for(var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return '';
	}


	function getParameterByName(name, url) {
		if (!url) 
			url = window.location.href;
		name = name.replace(/[\[\]]/g, '\\$&');
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
		results = regex.exec(url);
		if (!results)
			return null;
		if (!results[2])
			return '';
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}

	function setCookie(Key, Value){
		var now = new Date();
		var time = now.getTime();
		var expireTime = time + 1000*36000;
		now.setTime(expireTime);
		// var tempExp = 'Wed, 31 Oct 2012 08:50:17 GMT';
		document.cookie = Key+'='+Value+';expires='+now.toGMTString()+';path=/';
	}

	function storeQueryParamsFrm(){
		for(var i = 0; i < queryStringParams.labels.length; i++){
			var value = getParameterByName('utm_'+queryStringParams.labels[i]);
			console.log(value);
			if ((value  != null) && (value  != 'undefined') ) {
				queryStringParams.values[i] = value;
				setCookie(queryStringParams.labels[i],value);
			}
		}
	}

	storeQueryParamsFrm();

	if (document.getElementById('nomesia_form') !== null)
		setTimeout(function(){ 
			for(var i = 0; i < queryStringParams.labels.length; i++){
				console.log('label:' + queryStringParams.labels[i] + ' value from cookie: '+getCookie(queryStringParams.labels[i]));
				document.getElementsByName(queryStringParams.labels[i])[0].value = getCookie(queryStringParams.labels[i]);
				console.log( document.getElementsByName(queryStringParams.labels[i])[0].value);
			}
		}, 3000);


});



// JQuery

jQuery.noConflict()(function($) {

	"use strict";
	
	$(document).ready(function() {

		// Owl carousel init
		$('.owl-carousel').owlCarousel({
			loop: true,
			autoplay: true,
			lazyLoad: true,
			margin: 10,
			nav: true,
			navText: ['<i class="fa-solid fa-angle-left fa-xl" aria-hidden="true"></i>','<i class="fa-solid fa-angle-right fa-xl" aria-hidden="true"></i>'],
			dots: false,
			responsive: {
				0:{
					items: 1
				},
				576: {
					items: 2
				},
				1000: {
					items: 3
				}
			}
		})

	});

});